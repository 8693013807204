<template>
    <div class="container-xl" v-if="!$store.getters.isForbidden">
      <div class="card">
        <div class="card-header border-0 justify-content-between">
          <div class="card-title">Rewards List</div>
          <div class="flex flex-row mb-2 pr-2 float-right">
            <!-- <el-select size="small" v-model="merchant_id" placeholder="Merchant" class="mr-2" filterable remote :remote-method="searchMerchant">
              <el-option
                v-for="item in merchantList"
                :key="item.merchant_id"
                :label="item.merchant_name"
                :value="item.merchant_id">
              </el-option>
            </el-select> -->
            <el-button type="primary" @click="doShowModalCreate" size="small"><font-awesome-icon icon="plus"/> Add</el-button>
          </div>
        </div>
        <div class="card-table table-responsive">
          <!-- <div class="flex flex-row-reverse mb-2 pr-2">
            <el-button @click="searchHandler" size="small">Search</el-button>
            <el-select size="small" v-model="merchant_id" placeholder="Merchant" class="mr-2" filterable remote :remote-method="searchMerchant">
              <el-option
                v-for="item in merchantList"
                :key="item.merchant_id"
                :label="item.merchant_name"
                :value="item.merchant_id">
              </el-option>
            </el-select>
          </div> -->
          <table class="table table-vcenter">
            <thead>
              <tr>
                <th>Checkpoint</th>
                <th>Start at</th>
                <th>End at</th>
                <th>Merchant</th>
                <th width="30%">Voucher</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody v-loading="isLoading">
              <tr v-for="(item, i) in list" :key="i">
                <td>{{ item.period }}</td>
                <td class="text-nowrap text-muted">{{ item.start_at_str }}</td>
                <td class="text-nowrap text-muted">{{ item.end_at_str }}</td>
                <td class="text-nowrap text-muted">{{ item.voucher.merchant.merchant_name }}</td>
                <td class="td-truncate">
                  <div class="text-truncate">
                    {{ item.voucher.voucher_name }}
                  </div>
                </td>
                <td><span class="tag" :class="item.status_color_str">{{ item.status_str }}</span></td>
                <td>
                  <button class="btn btn-secondary btn-sm dropdown-toggle" data-toggle="dropdown">Actions</button>
                  <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow p-2" style="width: 200px">
                    <a class="dropdown-item btn-sm cursor-pointer" @click="confirmUpdateData(item.voucher_week_id, 1)"><font-awesome-icon icon="toggle-on" class="text-warning"/>&nbsp; Enable Data</a>
                    <!-- <a class="dropdown-item btn-sm cursor-pointer" @click="confirmUpdateData(item.voucher_week_id, 0)"><font-awesome-icon icon="trash" class="text-danger"/>&nbsp; Delete Data</a> -->
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <el-empty description="No data found" v-if="total_rows == 0" :image="emptyStateImage"></el-empty>
        </div>
        <div class="card-footer d-flex justify-content-between">
          <small>Showing {{ showingFrom }} to {{ showingUntil }} of {{ total_rows }} entries</small>
          <div class="inline-table">
            <b-pagination
              class="table-cell"
              @change="pageChangeHandler"
              :per-page="per_page"
              :limit="5"
              :total-rows="total_rows"
              v-model="page"
            />
          </div>
        </div>
        <b-modal v-model="showModalCreate" :title="modalTitle" hide-footer>
          <b-form @submit.prevent="createNewRewards" @reset="closeModalCreate">
            <b-form-group label="Checkpoint">
              <el-input
                v-model="v$.form.period.$model"
                placeholder="Input period"
                type="number"/>
              <b-form-invalid-feedback :state="!v$.form.period.$invalid" v-if="v$.form.period.$errors[0]">
                <span> {{ v$.form.period.$errors[0].$message }}</span>
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group label="Rewards Start Date">
              <el-date-picker
                :picker-options="datePickerOptions"
                class="w-full"
                v-model="v$.form.start_at.$model"
                type="date"
                placeholder="Pick start date"
                format="dd MMMM yyyy"
                value-format="yyyy-MM-dd"/>
              <b-form-invalid-feedback :state="!v$.form.start_at.$invalid" v-if="v$.form.start_at.$error">
                <span v-if="v$.form.start_at.$errors[0].$validator !== 'minValue'"> {{ v$.form.start_at.$errors[0].$message }}</span>
                <span v-else> Value must below {{ moment(v$.form.end_at.$model).format('YYYY-MM-DD') }}</span>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Promo End Date">
              <el-date-picker
                :picker-options="datePickerOptions"
                class="w-full"
                v-model="v$.form.end_at.$model"
                type="date"
                placeholder="Pick start date"
                format="dd MMMM yyyy"
                value-format="yyyy-MM-dd"/>
              <b-form-invalid-feedback :state="!v$.form.end_at.$invalid" v-if="v$.form.end_at.$error">
                <span v-if="v$.form.end_at.$errors[0].$validator !== 'minValue'"> {{ v$.form.end_at.$errors[0].$message }}</span>
                <span v-else> Value must above {{ moment(v$.form.start_at.$model).format('YYYY-MM-DD') }}</span>
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group label="Voucher">
              <el-select :loading="isLoadingVoucher" v-model="v$.form.voucher_id.$model" placeholder="Select or search voucher" class="w-full" filterable remote :remote-method="searchVoucher">
              <el-option
                v-for="item in list_voucher"
                :key="item.voucher_id"
                :label="item.voucher_name"
                :value="item.voucher_id">
              </el-option>
              </el-select>
              <b-form-invalid-feedback :state="!v$.form.voucher_id.$invalid" v-if="v$.form.voucher_id.$errors[0]">
                <span> {{ v$.form.voucher_id.$errors[0].$message }}</span>
              </b-form-invalid-feedback>
            </b-form-group>

            <div class="d-flex flex-row float-right">
              <el-button @click="confirmCreatePromo" :loading="loading.create_promo" class="mr-2 ml-2" size="small" type="primary">Submit</el-button>
              <el-button @click="closeModalCreatePromo" size="small" class="mr-2">Cancel</el-button>
              <!-- <b-button class="mr-2" size="sm" @click="closeModalGiveVoucher" type="reset" variant="secondary">Cancel</b-button> -->
            </div>
          </b-form>
        </b-modal>
      </div>
    </div>
</template>
<script>
import moment from 'moment';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import popupErrorMessages from '@/library/popup-error-messages';
import { GET_VOUCHERS } from '@/store/modules/vouchers';
import { GET_REWARDS, POST_REWARDS, UPDATE_REWARDS } from '@/store/modules/rewards';
import emptyStateImage from '@/assets/images/empty-state.png';

export default {
  name: 'REWARDS',
  metaInfo: {
    title: 'REWARDS',
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      moment,
      loading: {
        create_promo: false,
      },
      msgConfirm: '',
      emptyStateImage,
      merchantList: [],
      merchant_id: '',
      filter_merchant_loading: false,
      per_page: 20,
      total_rows: 0,
      page: 1,
      list: [],
      isLoading: true,
      loader: null,
      search_keyword: '',
      search_by: '',
      search_merchant_keyword: '',
      showModalCreate: false,
      modalType: 'add',
      modalTitle: this.modalAction === 'add' ? 'Create new Rewards' : 'Edit Rewards',
      form: {
        period: '',
        start_at: moment().format('YYYY-MM-DD'),
        end_at: moment().add(7, 'days').format('YYYY-MM-DD'),
        voucher_id: '',
      },
      datePickerOptions: {
        disabledDate: (date) => moment(date).isBefore(moment(), 'days'),
      },
      isLoadingVoucher: false,
      list_voucher: [],
      voucher: {
        search_by: 'voucher_name',
        search_keyword: '',
      },
    };
  },
  validations() {
    return {
      form: {
        period: {
          required,
        },
        start_at: {
          required,
          minValue: (val) => moment(val).isSameOrBefore(this.form.end_at),
        },
        end_at: {
          required,
          minValue: (val) => moment(val).isSameOrAfter(this.form.start_at),
        },
        voucher_id: {
          required,
        },
      },
    };
  },
  computed: {
    showingFrom() {
      return this.total_rows ? ((this.page - 1) * this.per_page) + 1 : 0;
    },
    showingUntil() {
      if (this.total_rows) {
        if (this.list.length < this.per_page) {
          return this.total_rows;
        }
        return this.page * this.per_page;
      }
      return 0;
    },
  },
  async mounted() {
    const loader = this.$loading.show();
    await this.getList();
    loader.hide();
  },
  methods: {
    pageChangeHandler(page) {
      this.page = page;
      this.getList();
    },
    searchHandler() {
      this.page = 1;
      if (this.merchant_id !== null) {
        this.search_by = 'merchant_id';
        this.search_keyword = this.merchant_id;
      }
      this.getList();
    },
    async getList() {
      this.isLoading = true;
      await this.$store.dispatch(GET_REWARDS, {
        page: this.page,
        per_page: this.per_page,
        search_by: this.search_by,
        search_keyword: this.search_keyword,
      }).catch(() => {});
      const { rows, count } = this.$store.getters.rewards;
      this.total_rows = count || 0;
      this.list = rows;
      this.isLoading = false;
    },
    doShowModalCreate() {
      this.v$.form.$touch();
      this.modalType = 'add';
      this.getListVoucher();
      this.showModalCreate = true;
    },
    closeModalCreate() {
      this.showModalCreate = false;
    },
    confirmCreatePromo() {
      if (this.modalType === 'edit') {
        this.doUpdatePromo();
        return;
      }
      this.$confirm('You will create a new Rewards. Make sure you have input data correctly. Continue?', 'Confirmation', {
        type: 'info',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            this.doCreateRewards();
          }
          done();
        },
      }).catch(() => {});
    },
    closeModalCreatePromo() {
      this.form = {
        name: '',
        type: 'voucher_code',
        discount_type: 'percent',
        voucher_code: '',
        discount_amount: 0,
        start_at: moment().format('YYYY-MM-DD'),
        end_at: moment().add(7, 'days').format('YYYY-MM-DD'),
      };
      this.showModalCreate = false;
    },
    async getListVoucher() {
      this.isLoadingVoucher = true;
      await this.$store.dispatch(GET_VOUCHERS, {
        search_by: this.voucher.search_by,
        search_keyword: this.voucher.search_keyword,
      }).catch(() => {});
      const { rows } = this.$store.getters.vouchers;
      this.list_voucher = rows;
      this.isLoadingVoucher = false;
    },
    searchVoucher(query) {
      this.timeout = setTimeout(() => {
        this.voucher.search_keyword = query;
        this.getListVoucher();
      }, 1000);
    },
    async doCreateRewards() {
      this.loading.create_promo = true;
      console.log(this.form);
      // /*
      await this.$store.dispatch(POST_REWARDS, this.form)
        .then(() => {
          this.$message({
            title: 'Success',
            type: 'success',
            message: 'Rewards created',
          });
          this.showModalCreate = false;
          this.getList();
        }).catch((err) => {
          popupErrorMessages(err.response.data);
        });
      // */
      this.loading.create_promo = false;
    },
    async confirmUpdateData(_id, _status) {
      switch (_status) {
        case 0:
          this.msgConfirm = 'delete';
          break;
        default:
          this.msgConfirm = 'activate';
      }
      this.form = {
        id: _id,
        status: _status,
      };
      this.$confirm(`You will ${this.msgConfirm} this data. Continue?`, 'Confirmation', {
        type: 'info',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            done();
            instance.confirmButtonLoading = true;
            await this.$store.dispatch(UPDATE_REWARDS, this.form)
              .then(() => {
                this.$message({
                  title: 'Success',
                  type: 'success',
                  message: `Data ${this.msgConfirm}d`,
                });
                this.getList();
              }).catch((err) => {
                popupErrorMessages(err.response.data);
              });
            instance.confirmButtonLoading = false;
            return;
          }
          done();
        },
      }).catch(() => {});
    },
  },
};
</script>
